import { useRoutes } from 'react-router-dom';

import LoginRoutes from './LoginRoutes';
// import MainRoutes from './MainRoutes';
// import GuestRoutes from './GuestRoutes';
import useAuth from '../hooks/useAuth';
import CoreRoutes from './CoreRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { isLoggedIn } = useAuth();

  console.log(isLoggedIn);
  let routes;
  if (isLoggedIn) {
    routes = [...CoreRoutes, LoginRoutes];
  } else {
    routes = [...CoreRoutes, LoginRoutes];
  }
  // return useRoutes(routes);
  return useRoutes(routes);
}
