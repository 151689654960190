import { get, post, put, destroy } from 'utils/axios';

//
/* Basic CRUD:
index  <- GET all items
single <- GET single item by uuid
create <- POST an item
update <- PUT data to an item
remove <- DELETE an item
Specific:
singleByEmail
removeAll */

export const productEndpoints = {
  baseUrl: `/products`
};

export const Products = {
  index: async (params, startPage) => {
    let hasNext = true;
    const products = {};
    let page = startPage ?? 1;
    let query = params ?? {};
    let url = `${productEndpoints.baseUrl}/`; // ?page=${page}`;

    query.page = page;
    while (hasNext) {
      hasNext = false;
      const response = await get(url, { params: query });
      console.log(response);
      console.log(products);

      if (response.status == 200) {
        let pageResults = response.data.results;
        console.log(pageResults);
        if (products.data) {
          products.data = [...products.data, ...pageResults];
        } else {
          products.data = pageResults;
        }
        if (response.data.next) {
          query.page = query.page + 1;
          //url = response.data.next;
          hasNext = true;
        }
      }
    }
    return products;
  },
  single: (uuid) => get(`${productEndpoints.baseUrl}/${uuid}/`),
  getPatents: (uuid) => {
    return get(`${productEndpoints.baseUrl}/${uuid}/patents/`);
  },
  addPatents: (uuid, params) => {
    return post(`${productEndpoints.baseUrl}/${uuid}/add_patents/`, params);
  },
  removePatents: (uuid, params) => {
    return post(`${productEndpoints.baseUrl}/${uuid}/remove_patents/`, params);
  },
  create: (params) => post(`${productEndpoints.baseUrl}/`, params),
  update: (uuid, params) => {
    // if (typeof params.category !== 'object'){
    //     params.category = {
    //         uuid:params.category
    //     }
    // }
    return put(`${productEndpoints.baseUrl}/${uuid}/`, params);
  },
  remove: (uuid) => destroy(`${productEndpoints.baseUrl}/${uuid}/`)
};
