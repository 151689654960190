// project-import
import { ThemeMode } from 'config';

// ==============================|| PRESET THEME - Trouvai Vibrant ||============================== //

const Theme1 = (colors, mode) => {
  const { grey } = colors;
  const greyColors = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16]
  };
  const contrastText = '#fff';

  // Base theme colors from the new scheme
  let primaryColors = ['#FFD0DA', '#FF99A8', '#FF6376', '#FF204E', '#E71C44', '#A0153E', '#7C1038', '#5D0E41', '#4B0B35', '#380929'];
  let secondaryColors = ['#D4C2D8', '#BFA3B9', '#A7849A', '#8A637D', '#735265', '#5D0E41', '#4C0733', '#3B0526', '#2A0419', '#1A030D'];

  let errorColors = ['#FFEBEE', '#FFCDD2', '#EF9A9A', '#E57373', '#F44336', '#D32F2F', '#B71C1C'];
  let warningColors = ['#FFF3E0', '#FFE0B2', '#FFCC80', '#FFB74D', '#FF9800', '#F57C00', '#E65100'];
  let infoColors = ['#E3F2FD', '#BBDEFB', '#90CAF9', '#64B5F6', '#2196F3', '#1976D2', '#0D47A1'];
  let successColors = ['#E8F5E9', '#C8E6C9', '#A5D6A7', '#81C784', '#66BB6A', '#43A047', '#2E7D32'];

  if (mode === ThemeMode.DARK) {
    primaryColors = ['#380929', '#4B0B35', '#5D0E41', '#7C1038', '#A0153E', '#E71C44', '#FF204E', '#FF6376', '#FF99A8', '#FFD0DA'];
    secondaryColors = ['#1A030D', '#2A0419', '#3B0526', '#4C0733', '#5D0E41', '#735265', '#8A637D', '#A7849A', '#BFA3B9', '#D4C2D8'];
    errorColors = ['#B71C1C', '#D32F2F', '#F44336', '#E57373', '#EF9A9A', '#FFCDD2', '#FFEBEE'];
    warningColors = ['#E65100', '#F57C00', '#FF9800', '#FFB74D', '#FFCC80', '#FFE0B2', '#FFF3E0'];
    infoColors = ['#0D47A1', '#1976D2', '#2196F3', '#64B5F6', '#90CAF9', '#BBDEFB', '#E3F2FD'];
    successColors = ['#2E7D32', '#43A047', '#66BB6A', '#81C784', '#A5D6A7', '#C8E6C9', '#E8F5E9'];
  }

  return {
    primary: {
      lighter: primaryColors[0],
      100: primaryColors[1],
      200: primaryColors[2],
      light: primaryColors[3], // #FF204E (Bright Red)
      400: primaryColors[4],
      main: primaryColors[5], // #A0153E (Deep Red)
      dark: primaryColors[6],
      700: primaryColors[7],
      darker: primaryColors[8],
      900: primaryColors[9],
      contrastText
    },
    secondary: {
      lighter: secondaryColors[0],
      100: secondaryColors[1],
      200: secondaryColors[2],
      light: secondaryColors[3],
      400: secondaryColors[4],
      main: secondaryColors[5], // #5D0E41 (Dark Purple)
      dark: secondaryColors[6],
      700: secondaryColors[7],
      darker: secondaryColors[8],
      900: secondaryColors[9],
      contrastText: secondaryColors[0]
    },
    error: {
      lighter: errorColors[0],
      light: errorColors[1],
      main: errorColors[2],
      dark: errorColors[3],
      darker: errorColors[4],
      contrastText
    },
    warning: {
      lighter: warningColors[0],
      light: warningColors[1],
      main: warningColors[2],
      dark: warningColors[3],
      darker: warningColors[4],
      contrastText: greyColors[100]
    },
    info: {
      lighter: infoColors[0],
      light: infoColors[1],
      main: infoColors[2],
      dark: infoColors[3],
      darker: infoColors[4],
      contrastText
    },
    success: {
      lighter: successColors[0],
      light: successColors[1],
      main: successColors[2],
      dark: successColors[3],
      darker: successColors[4],
      contrastText
    },
    grey: greyColors
  };
};

export default Theme1;
