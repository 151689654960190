import React, { useEffect, useState } from 'react';
// import './App.css';
import ReactGA from 'react-ga4';

// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';

import Loader from 'components/Loader';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/common/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

import { dispatch } from 'store';
import { fetchMenu } from 'store/reducers/menu';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import HomePage from 'pages/static/HomePage';

ReactGA.initialize('G-N9PCEGYX1G');

const App = () => {
  const [loading, setLoading] = useState(true);

  console.log('loaded');

  useEffect(() => {
    console.log('loaded');
    // fetch dashboard menu from API
    dispatch(fetchMenu()).then(() => {
      setLoading(false);
    });
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search
    });
  }, []);

  if (loading) return <Loader />;

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Notistack>
                  <Routes />
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
  // return (
  //   <ThemeCustomization>
  //     <RTLLayout>
  //       <Locales>
  //         <ScrollTop>
  //           <AuthProvider>
  //             <>
  //               <Notistack>
  //                 <Routes />
  //                 <Snackbar />
  //               </Notistack>
  //             </>
  //           </AuthProvider>
  //         </ScrollTop>
  //       </Locales>
  //     </RTLLayout>
  //   </ThemeCustomization>
  // );
};

export default App;
