import { lazy } from 'react';
import CommonLayout from 'components/layout/CommonLayout';
import Loadable from 'components/common/Loadable';
import { Navigate } from 'react-router';

const HomePage = Loadable(lazy(() => import('pages/static/HomePage')));
const Landing = Loadable(lazy(() => import('pages/landing')));
const AboutUsPage = Loadable(lazy(() => import('pages/static/core/AboutUsPage')));
const FaqsPage = Loadable(lazy(() => import('pages/static/core/FaqsPage')));
const AppContactUS = Loadable(lazy(() => import('pages/static/core/ContactUsPage')));
const PrivacyPolicy = Loadable(lazy(() => import('pages/static/core/PrivacyPolicyPage')));
const TermsOfService = Loadable(lazy(() => import('pages/static/core/TermsOfServicePage')));

// Business Segment Specific Pages
const BusinessSegments = Loadable(lazy(() => import('pages/discovery/BusinessSegmentsPage')));
const DiscoveryPage = Loadable(lazy(() => import('pages/discovery/DiscoveryPage')));
const StartupPipelinePage = Loadable(lazy(() => import('pages/discovery/StartupPipelinePage')));
const KnownBlogsListPage = Loadable(lazy(() => import('pages/discovery/resources/KnownBlogsListPage')));

const IdeationValidation = Loadable(lazy(() => import('pages/discovery/segments/IdeationValidationPage')));
const BusinessFormationLegal = Loadable(lazy(() => import('pages/discovery/segments/BusinessFormationLegalPage')));
const FinancialSetupPlanning = Loadable(lazy(() => import('pages/discovery/segments/FinancialSetupPlanningPage')));
const ProductDevelopmentOperations = Loadable(lazy(() => import('pages/discovery/segments/ProductDevelopmentOperationsPage')));
const MarketingSales = Loadable(lazy(() => import('pages/discovery/segments/MarketingSalesPage')));
const Marketing = Loadable(lazy(() => import('pages/discovery/marketing/MarketingPage')));
const BrandingOnlinePresence = Loadable(lazy(() => import('pages/discovery/segments/BrandingOnlinePresencePage')));
const OperationsTeamManagement = Loadable(lazy(() => import('pages/discovery/segments/OperationsTeamManagementPage')));
const FinancialHealthGrowth = Loadable(lazy(() => import('pages/discovery/segments/FinancialHealthGrowthPage')));
const AIPoweredInsights = Loadable(lazy(() => import('pages/discovery/segments/AIPoweredInsightsPage')));
const ComplianceRiskManagement = Loadable(lazy(() => import('pages/discovery/segments/ComplianceRiskManagementPage')));

// Topics
const Finance = Loadable(lazy(() => import('pages/discovery/finance/FinancePage')));

// ==============================|| AUTH ROUTING ||============================== //

const CoreRoutes = [
  {
    path: '/marketing',
    element: <CommonLayout layout="simple" />,
    children: [
      {
        path: '',
        element: <Marketing />
      }
    ]
  },
  {
    path: '/finance',
    element: <CommonLayout layout="simple" />,
    children: [
      {
        path: '',
        element: <Finance />
      }
    ]
  },
  {
    path: '/',
    element: <CommonLayout layout="simple" />,
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/landing',
        element: <Landing />
      },
      {
        path: 'about-us',
        element: <AboutUsPage />
      },
      {
        path: 'faqs',
        element: <FaqsPage />
      },
      {
        path: 'contact-us',
        element: <AppContactUS />
      },
      {
        path: 'privacy',
        element: <PrivacyPolicy />
      },
      {
        path: 'terms-of-service',
        element: <TermsOfService />
      },
      {
        path: 'discover',
        element: <DiscoveryPage />
      },
      {
        path: 'startup-stages',
        element: <StartupPipelinePage />
      },
      {
        path: 'business-segments',
        element: <BusinessSegments />
      },
      {
        path: 'vc-blog-list',
        element: <KnownBlogsListPage />
      },
      // Business Segment Specific Routes
      {
        path: 'business-segments/ideation-validation',
        element: <IdeationValidation />
      },
      {
        path: 'business-segments/business-formation-legal',
        element: <BusinessFormationLegal />
      },
      {
        path: 'business-segments/financial-setup-planning',
        element: <FinancialSetupPlanning />
      },
      {
        path: 'business-segments/product-development-operations',
        element: <ProductDevelopmentOperations />
      },
      {
        path: 'business-segments/marketing-sales',
        element: <MarketingSales />
      },
      {
        path: 'business-segments/branding-online-presence',
        element: <BrandingOnlinePresence />
      },
      {
        path: 'business-segments/operations-team-management',
        element: <OperationsTeamManagement />
      },
      {
        path: 'business-segments/financial-health-growth',
        element: <FinancialHealthGrowth />
      },
      {
        path: 'business-segments/ai-powered-insights',
        element: <AIPoweredInsights />
      },
      {
        path: 'business-segments/compliance-risk-management',
        element: <ComplianceRiskManagement />
      },
      {
        path: '*',
        element: <Navigate to={'/'} />
      }
    ]
  }
];

export default CoreRoutes;
