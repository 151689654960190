// third-party
import {
  //createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { Products } from 'apis/backend/services/products/Product';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  products: [],
  product: null
  //   relatedProducts: [],
  //   reviews: [],
  //   addresses: []
};

const product = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    // CLEAR ERROR
    clearError(state) {
      state.error = null;
    },

    // GET CATEGORIES
    getProductsSuccess(state, action) {
      state.products = action.payload;
    },

    // FILTER CATEGORIES
    filterProductsSuccess(state, action) {
      state.products = action.payload;
    },

    // CREATE NEW CATEGORY
    createProductSuccess(state, action) {
      state.products.push(action.payload);
    },

    // UPDATE CATEGORY
    updateProductSuccess(state, action) {
      const changedProduct = action.payload;
      state.products = state.products.map((obj) => {
        return obj.uuid === changedProduct.uuid ? changedProduct : obj;
      });
    },

    // DELETE CATEGORY
    deleteProductSuccess(state, action) {
      state.products = state.products.filter(function (product) {
        if (product.uuid !== action.payload) {
          return product;
        }
      });
    },

    // GET CATEGORY
    getProductSuccess(state, action) {
      state.product = action.payload;
    },

    addProductPatentsSuccess(state, action) {
      const updatedProduct = action.payload;
      // Update the product in the overall product list
      state.products = state.products.map((obj) => {
        return obj.uuid === updatedProduct.uuid ? updatedProduct : obj;
      });

      // Update the currently viewed product
      state.product = action.payload;
    },

    removeProductPatentsSuccess(state, action) {
      const updatedProduct = action.payload;
      // Update the product in the overall product list
      state.products = state.products.map((obj) => {
        return obj.uuid === updatedProduct.uuid ? updatedProduct : obj;
      });

      // Update the currently viewed product
      state.product = action.payload;
    }
  }
});

// Reducer
export default product.reducer;

// ----------------------------------------------------------------------
export function clearError() {
  return dispatch(product.actions.clearError());
}

export function getProducts(params) {
  return async () => {
    try {
      const response = await Products.index(params);
      dispatch(product.actions.getProductsSuccess(response.data));
    } catch (error) {
      dispatch(product.actions.hasError(error));
    }
  };
}

export function filterProducts(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/products/filter', { filter });
      dispatch(product.actions.filterProductsSuccess(response.data));
    } catch (error) {
      dispatch(product.actions.hasError(error));
    }
  };
}

export function createProduct(formValues) {
  return async () => {
    try {
      const response = await Products.create(formValues); // API to create element
      console.log(response);
      dispatch(product.actions.createProductSuccess(response.data)); // Dispatch resulting Product obj
    } catch (error) {
      console.log(error);
      dispatch(product.actions.hasError(error));
    }
  };
}

export function updateProduct(uuid, formValues) {
  return async (dispatch, getState) => {
    try {
      const response = await Products.update(uuid, formValues); // API to update element
      console.log(response);
      dispatch(product.actions.updateProductSuccess(response.data)); // Dispatch resulting Product obj
    } catch (error) {
      console.log(error);
      dispatch(product.actions.hasError(error));
      console.log(getState());
    }
  };
}

export function deleteProduct(uuid) {
  return async () => {
    try {
      const response = await Products.remove(uuid); // API to delete element
      console.log(response);
      dispatch(product.actions.deleteProductSuccess(uuid)); // Dispatch cat to delete
    } catch (error) {
      dispatch(product.actions.hasError(error));
    }
  };
}

export function getProduct(uuid) {
  return async () => {
    try {
      const response = await Products.single(uuid);
      dispatch(product.actions.getProductSuccess(response.data));
    } catch (error) {
      dispatch(product.actions.hasError(error));
    }
  };
}

export function addProductPatents(uuid, params) {
  return async () => {
    try {
      const response = await Products.addPatents(uuid, params);
      dispatch(product.actions.addProductPatentsSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(product.actions.hasError(error));
    }
  };
}

export function removeProductPatents(uuid, params) {
  return async () => {
    try {
      const response = await Products.removePatents(uuid, params);
      dispatch(product.actions.removeProductPatentsSuccess(response.data));
    } catch (error) {
      dispatch(product.actions.hasError(error));
    }
  };
}
